
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EntitySelectForOperator",
  props: {
    operator_id: {
      type: Number,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:operator_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const entityData = computed(() => store.getters.Entity);
    const inputEntity = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isEntityRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    // const { errorMessage, value } = useField("entity", isEntityRequired);

    const { errorMessage, value: element_id } = useField(
      "operator_entity",
      isEntityRequired
    );

    const getEntity = () => {
      if (!entityData.value.length) {
        store.commit("setLoading", false);
        ApiService.get("/api/entities/lists").then(({ data }) => {
          store.commit("setEntityOperator", data.entities);
          inputEntity.value.list = data.entities;
          inputEntity.value.options = data.entities;
        });
      } else {
        inputEntity.value.list = entityData.value.slice();
        inputEntity.value.options = entityData.value.slice();
      }
      const index = inputEntity.value.options.findIndex(
        (x) => x.name === "Todos"
      );
      if (props.multiselect) {
        if (index === -1) {
          inputEntity.value.options.splice(0, 0, {
            id: 0,
            name: "Todos",
          });
        }
      } else if (index !== -1) {
        inputEntity.value.options.splice(0, 1);
      }
    };
    const selectEntity = (query) => {
      if (query !== "") {
        inputEntity.value.loading = true;
        setTimeout(() => {
          inputEntity.value.loading = false;
          inputEntity.value.options = inputEntity.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputEntity.value.options.length) {
            store.commit("setLoading", false);
            ApiService.query(`/api/entities/lists`, {
              params: { per_page: 25, name: query },
            }).then(({ data }) => {
              inputEntity.value.list = data.entities;
              inputEntity.value.options = data.entities;
            });
          }
        }, 200);
      } else {
        inputEntity.value.options = entityData.value;
      }
    };

    watch(
      () => props.operator_id,
      (first) => {
        element_id.value = first;
      }
    );

    onMounted(() => {
      setTimeout(function () {
        getEntity();
      }, 3000);
    });

    return {
      element_id,
      inputEntity,
      errorMessage,
      getEntity,
      selectEntity,
    };
  },
};
