
import { onMounted, onUpdated, ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import { useI18n } from "vue-i18n";
import EntitySelect from "@/components/catalogs-select/EntitySelect.vue";
import PortsSelect from "@/components/catalogs-select/PortsSelect.vue";
import SPSelect from "@/components/catalogs-select/SPSelect.vue";
import EntitySelectForOperator from "@/components/catalogs-select/EntitySelectForOperator.vue";
import FlagSelect from "@/components/catalogs-select/FlagSelect.vue";
import { ElNotification } from "element-plus";
import { useStore } from "vuex";

interface FormCatalog {
  id?: number;
  name: string;
  lloyds_iso: string;
  owner_id?: number;
  operator_id: string;
  country_id: string;
  port_id?: number;
  manufactur_year?: number;
  fretwork?: number;
  capacity?: number;
  length?: number;
  beam?: number;
  call_sign?: string;
}

export default {
  name: "VesselsForm",
  components: {
    EntitySelectForOperator,
    Form,
    EntitySelect,
    PortsSelect,
    Field,
    ErrorMessage,
    FlagSelect,
    SPSelect,
  },
  props: {
    entityId: String,
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const submitButton = ref<HTMLElement | null>(null);
    const addElement = ref(false);
    const isLoading = ref(false);
    const tableData = ref<[] | any>([]);
    const form = ref<FormCatalog>({
      id: undefined,
      name: "",
      lloyds_iso: "",
      owner_id: undefined,
      operator_id: "",
      port_id: undefined,
      country_id: "",
      manufactur_year: undefined,
      fretwork: undefined,
      capacity: undefined,
      length: undefined,
      beam: undefined,
      call_sign: "",
    });
    const headerTable = ref([
      { column: t("synchronizeflagid"), key: "synchronize_flag_id" },
      { column: t("externalcode"), key: "external_code" },
    ]);

    const isNameRequired = (value) => {
      if (!value) {
        return t("rname");
      }
      return true;
    };

    const isIsoRequired = (value) => {
      if (!value) {
        return t("riso");
      }
      return true;
    };

    const createSync = () => {
      addElement.value = true;
      if (tableData.value.length > 0) {
        let invalidTable = false;
        tableData.value.forEach((item, index) => {
          if (index === tableData.value.length - 1) {
            invalidTable =
              item.synchronize_flag_id === "" || item.external_code === "";
          }
        });
        if (!invalidTable) {
          addLine();
        } else {
          ElNotification({
            title: "Error",
            message: t("rflagtabletable"),
            type: "error",
          });
        }
      } else {
        addLine();
      }
    };

    const addLine = () => {
      tableData.value.push({
        synchronize_flag_id: "",
        external_code: "",
      });
    };

    const removeElement = (index) => {
      tableData.value.splice(index, 1);
    };

    onUpdated(() => {
      console.clear();
    });

    onMounted(() => {
      console.clear();
      if (props.entityId) {
        store.commit("setLoadingStatus", true);
        ApiService.get("/api/vessel/" + props.entityId).then(({ data }) => {
          form.value = { ...data };
          data.synchronize_vessel.forEach((x) => {
            tableData.value.push({
              synchronize_flag_id: x.synchronize_flag_id,
              external_code: x.external_code,
            });
          });
          store.commit("setLoadingStatus", false);
        });
      }
    });

    const createElement = async (payload, resetForm) => {
      try {
        store.commit("setLoadingStatus", true);
        const resp = (await ApiService.post("/api/vessel", payload)).data;
        emit("success", resp);
        resetForm();
        submitButton.value?.removeAttribute("data-kt-indicator");
      } catch (error) {
        store.commit("setLoadingStatus", false);
        submitButton.value?.removeAttribute("data-kt-indicator");
      }
    };

    const updateElement = (payload, resetForm) => {
      store.commit("setLoadingStatus", true);
      ApiService.put("/api/vessel/" + props.entityId, payload)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
          form.value = data;
          resetForm();
        })
        .catch(() => {
          store.commit("setLoadingStatus", false);
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const onSumbit = (value, { resetForm }) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        const payload = {
          id: form.value.id,
          name: form.value.name,
          lloyds_iso: form.value.lloyds_iso,
          owner_id: form.value.owner_id,
          operator_id: form.value.operator_id,
          port_id: form.value.port_id,
          manufactur_year: form.value.manufactur_year,
          fretwork: form.value.fretwork,
          capacity: form.value.capacity,
          length: form.value.length,
          beam: form.value.beam,
          call_sign: form.value.call_sign,
          country_id: form.value.country_id,
          synchronize_flags: tableData.value,
        };
        if (form.value.id) {
          updateElement(payload, resetForm);
        } else {
          createElement(payload, resetForm);
        }
      }
    };

    return {
      submitButton,
      form,
      addElement,
      isLoading,
      tableData,
      headerTable,
      onSumbit,
      isNameRequired,
      isIsoRequired,
      createSync,
      removeElement,
      updateElement,
      createElement,
    };
  },
};
