
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "PortsSelect",
  props: {
    port_id: {
      type: [Number, Array],
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "element_id",
    },
    mounted: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:port_id", "changePort"],

  setup(props, { emit }) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const portData = computed(() => store.getters.Port);
    const inputPort = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isPortRequired = (value) => {
      if (props.required && !value) {
        return t("rportid");
      }
      return true;
    };

    const { errorMessage, value } = useField("port", isPortRequired);

    const getPort = () => {
      ApiService.get("/api/ports/lists").then(({ data }) => {
        store.commit("setPort", data.ports);
        inputPort.value.list = data.ports;
        inputPort.value.options = data.ports;
      });
    };
    const selectPort = (query) => {
      if (query !== "") {
        inputPort.value.loading = true;
        setTimeout(() => {
          inputPort.value.loading = false;
          inputPort.value.options = inputPort.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputPort.value.options.length) {
            ApiService.query(`/api/ports/lists`, {
              params: { per_page: 10, name: query },
            }).then(({ data }) => {
              data.ports.forEach((item) => {
                store.commit("addPort", item);
              });
              inputPort.value.list = portData.value;
              inputPort.value.options = data.ports;
            });
          }
        }, 200);
      } else {
        inputPort.value.options = portData.value;
      }
    };

    watch(
      () => props.port_id,
      (first) => {
        element_id.value = first;
        value.value = first;
        const selected = inputPort.value.list.find((x) => x.id === first);
        if (selected) {
          emit("changePort", selected.name);
        }
        if (
          !inputPort.value.options.map((x) => x.id).includes(first) &&
          first
        ) {
          ApiService.get("/api/ports/" + props.port_id).then(({ data }) => {
            inputPort.value.options.push(data);
          });
        }
      }
    );

    onMounted(() => {
      element_id.value = props.port_id;
      value.value = props.port_id;
      if (props.mounted) {
        getPort();
      } else {
        inputPort.value.list = portData.value;
        inputPort.value.options = portData.value;
      }
    });

    return {
      element_id,
      value,
      inputPort,
      errorMessage,
      getPort,
      selectPort,
    };
  },
};
