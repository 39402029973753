
import { computed, onMounted, ref, watch } from "vue";
import ApiService from "../../core/services/ApiService";
import { useI18n } from "vue-i18n";
import { useField } from "vee-validate";
import { useStore } from "vuex";

export default {
  name: "EntitySelect",
  props: {
    type_entity_ids: {
      type: [Number, Array],
      default: undefined,
    },
    entity_id: {
      type: [Number, Array],
      default: undefined,
    },
    office_id: {
      type: Array,
      default: [] as any,
    },
    entity_data: {
      type: Object,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    all: {
      type: Boolean,
      default: true,
    },
    datas: {
      type: Array,
      default: [] as any,
    },
    mounted: {
      type: Boolean,
      default: true,
    },
    custom_class: {
      type: String,
      default: "custom-select w-100 p-0 chevron",
    },
  },
  emits: ["update:entity_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const entityData = computed(() => store.getters.Entity);
    const inputEntity = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isEntityRequired = (value) => {
      if (props.required && !value) {
        return t("rentity");
      }
      return true;
    };

    // const { errorMessage, value } = useField("entity", isEntityRequired);

    const { errorMessage, value: element_id } = useField(
      "operator_entity",
      isEntityRequired
    );

    const getEntity = () => {
      ApiService.query(`/api/entities/lists`, {
        params: {
          per_page: 5,
          name: "",
          office_id: props.office_id,
          type_entity_ids: props.type_entity_ids,
        },
      }).then(({ data }) => {
        store.commit("setEntity", data.entities);
        inputEntity.value.list = data.entities;
        inputEntity.value.options = data.entities;
      });
    };

    const selectEntity = (query) => {
      if (query !== "") {
        inputEntity.value.loading = true;
        setTimeout(() => {
          inputEntity.value.loading = false;
          inputEntity.value.options = inputEntity.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
          if (!inputEntity.value.options.length) {
            ApiService.query(`/api/entities/lists`, {
              params: {
                per_page: 5,
                office_id: props.office_id,
                name: query,
                type_entity_ids: props.type_entity_ids,
              },
            }).then(({ data }) => {
              data.entities.forEach((item) => {
                store.commit("addEntity", item);
              });
              inputEntity.value.list = entityData.value;
              inputEntity.value.options = data.entities;
            });
          }
        }, 200);
      } else {
        inputEntity.value.options = entityData.value;
      }
    };

    watch(
      () => props.entity_data,
      (first) => {
        if (first != undefined) {
          element_id.value = first.id;
          const entitiy_ids = inputEntity.value.options.map((x) => x.id);
          if (!entitiy_ids.includes(first.id)) {
            store.commit("addEntity", first);
          }
        }
      },
      { deep: true }
    );
    watch(
      () => props.datas,
      (first) => {
        console.log("watch props.datas", props.datas);
        if (first) {
          inputEntity.value.options = props.datas;
          inputEntity.value.list = props.datas;
        }
      },
      { deep: true }
    );

    /*watch(
      () => element_id.value,
      (first) => {
        if (first != undefined) {
          value.value = first;
        }
      },
      { deep: true }
    );*/
    //
    // watch(
    //   () => props.entity_id,
    //   (first) => {
    //     if (first != undefined) {
    //       element_id.value = first;
    //       value.value = first;
    //       if (
    //         !inputEntity.value.options.map((x) => x.id).includes(first) &&
    //         inputEntity.value.options.length > 0
    //       ) {
    //         ApiService.get("/api/entities/" + first).then(({ data }) => {
    //           inputEntity.value.options.push(data);
    //           store.commit("addEntity", first);
    //         });
    //       }
    //     }
    //   },
    //   { deep: true }
    // );

    onMounted(() => {
      if (props.mounted) {
        getEntity();
      } else {
        inputEntity.value.list = props.datas;
        inputEntity.value.options = props.datas;
        element_id.value = props.entity_id;
      }
    });

    return {
      element_id,
      inputEntity,
      errorMessage,
      getEntity,
      selectEntity,
    };
  },
};
