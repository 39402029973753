
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import { useField } from "vee-validate";
import ApiService from "@/core/services/ApiService";

export default {
  name: "FlagSelect",
  props: {
    flag_id: {
      type: Number,
      default: undefined,
    },
    flagData: {
      type: Array,
      default: undefined,
    },
    size: {
      type: String,
      default: "small",
    },
    required: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
      default: false,
    },
    back: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:flag_id"],

  setup(props) {
    const { t } = useI18n();
    const store = useStore();
    const element_id = ref("");
    const inputFlag = ref({
      loading: false,
      options: [] as any,
      list: [] as any,
    });

    const isFlagRequired = (value) => {
      if (props.required && !value) {
        return t("rflagid");
      }
      return true;
    };

    const { errorMessage, value } = useField("flagId", isFlagRequired);

    const getFlag = (query) => {
      if (
        props.load &&
        (!props.flagData || props.flagData.length == 0 || query != "")
      ) {
        store.commit("setLoading", false);
        ApiService.query("/api/synchronizes/lists", {
          params: {
            code: query,
            use_in: ["A", "F"],
          },
        }).then(({ data }) => {
          inputFlag.value.list = data.flag_synchronizes;
          inputFlag.value.options = data.flag_synchronizes;
        });
      } else {
        if (props.flagData) {
          inputFlag.value.list = props.flagData;
          inputFlag.value.options = props.flagData;
        }
      }

      if (
        !inputFlag.value.options.map((x) => x.id).includes(props.flag_id) &&
        props.flag_id
      ) {
        ApiService.get("/api/synchronizes/" + props.flag_id).then(
          ({ data }) => {
            inputFlag.value.options.push(data);
          }
        );
      }
    };

    watch(
      () => props.flag_id,
      (first) => {
        element_id.value = first;
        if (
          !inputFlag.value.options.map((x) => x.id).includes(first) &&
          inputFlag.value.options.length > 0
        ) {
          ApiService.get("/api/synchronizes/" + first).then(({ data }) => {
            inputFlag.value.options.push(data);
          });
        }
      }
    );

    onMounted(() => {
      getFlag("");
      if (props.flag_id) {
        element_id.value = props.flag_id;
      }
    });

    return {
      element_id,
      inputFlag,
      errorMessage,
      getFlag,
    };
  },
};
